$corPrim : #ab1711;
$corSec1 : #1B3369;
$corSec2 : #274280;
$corSec3 : #3D558D;
$corSec4 : #7e8a90;

$greenBtn : #24A668;
$greenDarkBtn : #248668;
$cinzaBG : #F4F4F4;
$corCinza : #EAEAEA;
$corBranca : #FFFFFF;
$corB00 : #BB0000;
$corRedLight : #ff4131;
$corRedDark : #df4131;
$cor7F : #7F7F7F;
$corBF : #BFBFBF;
$preto25: rgba(0,0,0,0.25);
$preto13: rgba(0,0,0,0.13);
