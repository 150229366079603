@import './colors.scss';
@import './clear.scss';


#root{
    display: flex;
    flex:1;
    flex-direction: column;
    min-width: 990px;
}

body{
    .color{
        &-primary{
            color: $corPrim
        }
        &-secondary{
            color: $corSec1
        }
        &-green{
            color: $greenBtn
        }
        &-bg-green{
            background-color: $greenBtn !important
        }
        &-redLight{
            color: $corRedLight
        }
    }
    .bg{
        &-gray{
            background-color: $cinzaBG;
        }
    }
    .flex{
        display: flex;
        &-between{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-end{
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        
        &-end-end{
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
        &-start-end{
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
        }
        &-start{
            justify-content: flex-start;
            align-items: center;
            display: flex;
        }
        &-center{
            justify-content: center;
            align-items: center;
            display: flex;
        }
        &-col{
            flex-direction: column;
        }
        &-row{
            flex-direction: row;
        }
    }

    .m{
        &v{
            &-0{
                margin-top: 0!important;
                margin-bottom: 0!important;
            }
            &-1{
                margin-top: 5px;
                margin-bottom: 5px;
            }
            &-2{
                margin-top: 8px;
                margin-bottom: 8px;
            }
            &-3{
                margin-top: 13px;
                margin-bottom: 13px;
            }
            &-4{
                margin-top: 21px;
                margin-bottom: 21px;
            }
            &-5{
                margin-top: 34px;
                margin-bottom: 34px;
            }
        }
        &h{
            &-0{
                margin-left: 0;
                margin-right: 0;
            }
            &-1{
                margin-left: 5px;
                margin-right: 5px;
            }
            &-2{
                margin-left: 8px;
                margin-right: 8px;
            }
            &-3{
                margin-left: 13px;
                margin-right: 13px;
            }
            &-4{
                margin-left: 21px;
                margin-right: 21px;
            }
            &-5{
                margin-left: 34px;
                margin-right: 34px;
            }
        }
    }

    .p{
        &v{
            &-0{
                padding-top: 0;
                padding-bottom: 0;
            }
            &-1{
                padding-top: 5px;
                padding-bottom: 5px;
            }
            &-2{
                padding-top: 8px;
                padding-bottom: 8px;
            }
            &-3{
                padding-top: 13px;
                padding-bottom: 13px;
            }
            &-4{
                padding-top: 21px;
                padding-bottom: 21px;
            }
            &-5{
                padding-top: 34px;
                padding-bottom: 34px;
            }
        }
        &h{
            &-0{
                padding-left: 0;
                padding-right: 0;
            }
            &-1{
                padding-left: 5px;
                padding-right: 5px;
            }
            &-2{
                padding-left: 8px;
                padding-right: 8px;
            }
            &-3{
                padding-left: 13px;
                padding-right: 13px;
            }
            &-4{
                padding-left: 21px;
                padding-right: 21px;
            }
            &-5{
                padding-left: 34px;
                padding-right: 34px;
            }
        }
    }

    a{
        color: inherit;
    }

    .maxDialog{
        .MuiDialog{
            &-paperWidthSm{
                min-width: 850px;
            }
        }
        
        .MuiButton{
            &-text{
                padding: 8px 18px;
                &Primary{
                    margin: auto 21px;
                    color: $corBranca;
                    background-color: $greenBtn!important;
                }
            }
        }
    }

    .myDialog{
        .MuiDialog{
            &-paper{
                background-color: $corSec1;
                color: $corBranca;
                border-radius: 1em;
                padding: 1% 3%;
            }
            &ContentText-root{
                font-size: 13px;
                color: $corBranca;
            }
        }

        .MuiTextField{
            &-root{
                background-color: $corSec2;
            }
        }
        
        .MuiButton{
            &-text{
                padding: 8px 18px;
                &Primary{
                    margin: auto 21px;
                    color: $corBranca;
                    background-color: $greenBtn!important;
                }
            }
        }
    }

    .MuiOutlinedInput{
        &-input{
            padding: 8px 13px;
            color: $corSec1;
        }
        &-notchedOutline{
            border-color: $corCinza;
        }
    }
    .MuiChip{
        &-deleteIcon{
            &:hover{
                color: $corB00;
            }
        }
    }
    .MuiDialog-root{
        .MuiPaper-root, .MuiDialogContent-root{
            overflow-y: visible;
        }
    }
    .input{
        &-text{
            margin: 21px auto;
            border-radius: 8px!important;
            border-color: transparent;
            background-color: $corSec2!important;
            position: relative;
            div:nth-child(1){
                z-index: 1;
                div:nth-child(1){
                    width: 34px;
                    height: 26px;
                    position: relative;
                    svg{
                        position: absolute;
                        left: -3px;
                    }
                }
            }
            svg{
                color: $cinzaBG;
            }
            input{
                text-indent: 1em!important;
                font-size: 0.9em!important;
                color: $cinzaBG;
                &::placeholder{
                    color: $cinzaBG;
                    opacity: 0.5;
                }
                &:-ms-input-placeholder{
                    color: $cinzaBG;
                    opacity: 0.5;
                }
                &::-ms-input-placeholder{
                    color: $cinzaBG;
                    opacity: 0.5;
                }
            }
            &::after{
                content: " ";
                width: 39px;
                height: 55px;
                position: absolute;
                background-color: $corSec3;
                top: 0;
                left: 0;
            }
        }
    }

    .btn{
        &-action{
            padding: 8px 13px;
            color: $corBranca;
            background-color: $greenBtn!important;
        }
        &-confirm{
            padding: 8px 13px;
            color: $corBranca;
            background-color: $greenBtn!important;
            &:hover{
                background-color: $greenDarkBtn!important;
            }

        }
        &-cancel{
            padding: 8px 13px;
            color: $corSec2;
            background-color: $corCinza!important;
        }
        &-cancelRed{
            padding: 8px 13px;
            color: $corPrim;
            background-color: $corCinza!important;
            &-light{
                padding: 8px 13px;
                color: $corBranca;
                background-color: $corRedLight!important;
                &:hover{
                    background-color: $corRedDark!important;
                }
            }
        }
    }
}

.body{
    padding: 0 8%;
    &-title{
        color: $corSec1;
        font-size: large;
        font-weight: 500;
        margin-bottom: 13px;
    }
}

.box{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: $corBranca;
    margin: 21px 0;
    overflow: hidden;
    //box-shadow: 0 3px 5px $preto13; 
    &-bar{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
    }
    &-img{
        &-bar{
            position: absolute;
            width: 100%;
            background-color: $preto25;
            bottom: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            padding: 3px;
        }
        &-btn{
            color: $corBranca;
            width: auto!important;
            font-size: 21px;
            cursor: pointer;
        }
        &-sel{
            color: $corBF;
            position: relative;
            border: solid 1px $corBF;
            background-color: $corCinza;
            //width: 100%;
            min-height: 180px;
            svg{
                width: 50%;
            }
            &.show{
                &::after{
                    content: "";
                }
            }
        }
    }
    &-select{
        font-size: 13px!important;
        fieldset{ border-color: $corCinza!important; }
        select:focus{background-color: transparent!important;}
    }
    &-btns{
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 165px;
    }
    &-btn{
        font-size: 13px;
        margin: auto 0 auto 8px;
        padding: 8px 11px;
        color: $corBranca;
        background-color: $greenBtn;
        &:disabled, &.disabled{
            background-color: $corBF;
        }
        &-upd{
            color: $corPrim;
            font-size: 26px;
            background-color: transparent;
            height: 40px;
        }
        &-del{
            font-size: 13px;
            padding: 8px 13px;
            color: $cor7F;
            background-color: $corBranca;
            border: solid 1px $corCinza;
            &:hover{
                // font-size: 12px;
                // font-weight: 600;
                color: $corBranca;
                background-color: $corPrim;
                border-color: $corPrim;
            }
        }
    }
    &-panel{
        padding: 13px;
        border: solid 1px $corCinza;
    }
    &-tabs{
        display: flex;
    }
    &-tab{
        font-size: 11px;
        padding: 8px 21px;
        color: $corSec1;
        background-color: $cinzaBG;
        overflow: hidden;
        border-radius: 5px 5px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 1px 0 0;
        white-space: nowrap;
        &:hover{
            cursor: pointer;
            color: $corBranca;
            background-color: $corSec4;
        }
        &.sel{
            color: $corBranca;
            background-color: $corSec1;
        }
    }
    &-body{
        min-height: 50px;
        padding: 21px 5%;
        display: flex;
        flex-direction: column;
    }
    &-radio-group{
        margin-top: 21px; 
        flex-direction: row!important;
        align-items: center;
        justify-content: space-between;
        .MuiFormControlLabel-label{
            font-size: small;
        }
    }
    &-chip-list{
        width: 100%;
        border-radius: 5px;
        border: solid 1px $corCinza;
        padding: 8px;
        position: relative;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        &.clear{
            padding: 0;
            &::before{
                content: attr(label);
                color: $cor7F;
                padding: 5px 8px;
                top: auto;
                position: relative;
                display: inline-block;
                font-size: 16px;
            }
        }
        &::before{
            content: attr(label);
            color: $cor7F;
            position: absolute;
            top: -11px;
            padding: 0 5px;
            font-size: 0.9em;
            background-color: $corBranca;
        }
    }
}

.message{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: $corBranca;
    margin: 21px 0;
    box-shadow: 0 3px 5px $preto13; 
    &-header{
        background-color: $cor7F;
        border-radius: 8px 8px 0 0;
        padding: 13px 21px;
        color: $corBranca;
        display: flex;
        flex-direction: row;
        position: relative;
        &.primary{
            background-color: $corPrim;
        }
        &.secondary{
            background-color: $corSec1;
        }
        &-icon{ 
            margin-right: 13px;
        }
        &-titles{
            width: 100%;
        }
        &-title{
            font-size: 16px;
        }
        &-subtitle{
            margin-top: 5px;
            font-size: 13px;
        }
        &-date{
            top: 8px;
            right: 13px;
            position: absolute;
            white-space: nowrap;
            font-size: 10px;
        }
    }
    &-body{
        min-height: 100px;
        padding: 21px 5%;
        display: flex;
        div{
            margin: 21px 0 0;
            font-size: 16px;
        }
        p{
            margin-bottom: 1em;
        }
    }
}

.navbar{
    &-user{
        &-icon{
            background-color: $corBranca;
            font-size: 6em;
            padding: 0.1em;
            border-radius: 3em;
            height: 40px;
            color: $preto25;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            cursor: pointer;
        }
    }
    
}

.auth{
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $cinzaBG;
    &-navbar{
        width: 100%;
        padding: 13px 21px;
        position: fixed;
        top: 0;
        display: flex;
        background-color: $corSec1;
        justify-content: space-between;
        &-left{
            justify-content: flex-start;
            img{
                margin-left: 55px;
            }
        }
        &-right{
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        &-nets{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $corBranca;
            font-size:1.2em;
            min-width: 233px;
        }
        .btn-action{
            margin: auto 34px !important;
            letter-spacing: 0;
            padding: 8px 13px!important;
            font-size: 0.8em;
            font-weight: 600;
        }
    }
    &-box{
        margin-top: 55px;
        border-radius: 1em;
        background-color: $corSec1;
        min-width: 300px;
        min-height: 400px;
        padding: 5% 8%;
        text-align: center;
        box-shadow: 1px 3px 13px $preto25;
        display: flex;
        justify-content: center;
        align-items: center;
        &-header{
            display: flex;
            justify-content: flex-end;
            .btn{
                background-color: transparent;
                padding: 13px 21px;
                margin: auto 5px;
                color: $corBranca;
                border: solid 2px $corSec3;
                border-radius: 13px;
                font-weight: 600;
                font-size: 0.8em;
                &.off{
                    border-color: transparent;
                    color: $cinzaBG;
                    opacity: 0.5;
                    &:hover{
                        color: $corBranca;
                        opacity: 1;
                        border-color: $corSec3;
                    }
                }
            }
        }
    }
    &-logo{
        width: 200px;
    }
    &-options{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 21px 3px 8px;
        color: $corSec3;
        div{
            cursor: pointer;
            font-size: 0.8em;
            color: $cinzaBG;
            &:hover, &:focus{
                color: $corPrim;
                font-weight: 600;
            }
        }
    }
    .btn{
        &-action{
            padding: 13px 0;
            margin-top: 89px;
        }
    }
}

.toolbar{
    background-color: $corSec2;
    justify-content: space-between;
    .menu{
        &-icon{
            color: $corBranca
        }
        &-right{
            justify-content: flex-end;
        }
        &-left{
            display: flex;
            align-items: center;
            .logo{
                cursor: pointer;
                filter: drop-shadow(2px 4px 6px #274280);
            }
        }
    }
}

.panel{
    &-main{
        flex: 1;
        display: flex;
        margin-top: 54px;
        flex-direction: row;
        background-color: $cinzaBG;
        .MuiListItem-root{
            &.Mui-selected{
                color: $corBranca;
                background-color: $corPrim;
                &:hover{
                    color: $corBranca;
                    background-color: $corPrim;
                }
                &::after1{
                    content: "";
                    position: absolute;
                    width: 0; 
                    height: 0; 
                    border-top: 21px solid transparent;
                    border-bottom: 21px solid transparent;
                    
                    border-left: 10px solid $corPrim;
                    right: -10px;
                }
            }
        }
    }
    &-content{
        width: 100%;
        padding: 21px 13px;
    }
    &-left{
        z-index: 9;
        .MuiList{
            &Item-button{
                box-shadow: 0px 0px 1px $preto25;
                &:hover{
                    color: $corPrim;
                }
            }
            &-root{
                color: $cor7F;
            }
        }
    }
}

//>>> BOTÕES <<<
@mixin btn-default {
    color: $corBranca;
    background-color: $corSec2;
    width: 100%;
    padding: 0.8em;
    letter-spacing: 0.5em;
    border-radius: 0.5em;
}

.btn{
    &-action{
        @include btn-default;
        &:hover, &:focus{
            background-color: $corPrim;
        }
    }
    &-menu{
        padding: 5px 13px!important;
        margin-bottom: 13px!important;
    }
}

.title{
    font-size: 21px;
    margin: 0 8px 21px;
}

.editorClass{
    padding: 0 8px;
    min-height: 210px;
    border: solid 1px $corCinza;
}

.input{
    &-to{
        width: 100%;
        .MuiInputBase-formControl{
            margin-bottom: 13px;
            fieldset{
                border-color: $corCinza;
            }
        }
    }
    &-bordered{
        border: solid 1px $corCinza;
        padding: 8px;
        font-size:13px;
        width: 100%;
        &::placeholder{
            color: $cor7F;
            opacity: 1;
            //font-style: italic;
        }
        &:-ms-input-placeholder{
            color: $cor7F;
            opacity: 1;
            //font-style: italic;
        }
        &::-ms-input-placeholder{
            color: $cor7F;
            opacity: 1;
            //font-style: italic;
        }
    }
}
//>>> ÍCONES <<<
.icon{
    &-big{
        font-size: 1.5em;
        color: $corSec2;
    }
}


//>>> CAMPOS <<<
.input{
    &-text{
        border: solid 1px $corCinza;
        margin: 13px auto;
        font-size: 15px;
    }
}



//>>> Flex Table
$table-header: $corSec1;
$table-header-border:$corSec2;
$table-border:$cinzaBG;
$row-bg: $corCinza;


.table-container {
  display: block;
  margin: 2em auto;
  width: 99%;
  //max-width: 600px;

    div {
    box-sizing: border-box;
    }
}

.flex-table {
  display: flex;
  flex-flow: row wrap;
  border-left: solid 1px $table-border;
  transition: 0.5s;
  //&:first-of-type {
    //border-top: solid 1px $table-header-border;
    //border-left: solid 1px $table-header-border;
  //}
  &:first-of-type .flex-row {
    background: $table-header;
    color: $corBranca;
    border-color: $table-header-border;
  }
  &.row:nth-child(odd) .flex-row {
    background: $row-bg;
  }
  &:hover {
    background: $cinzaBG;
    transition: 500ms;
  }
}

.flex-row {
  //width: calc(100% / 4);
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px $table-border;
  border-bottom: solid 1px $table-border;
}

.rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.column {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
  .flex-row {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: solid 1px $table-border;
    &:hover {
      background: $cinzaBG;
      transition: 500ms;
    }
  }
}

.flex-cell {
  width: calc(100% / 3); //1px = border right
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px $table-border;
  //flex: 1 1 33.3%;
//   &:last-child {
//     // border-right: 0;
//   }
}

.wrapperClass{
    display: flex; flex-direction: column-reverse
}
.editor-label{
    position: absolute;
    left:10px;
    top:5px;
    padding: 0 5px;
    color: $cor7F;
    font-size: 13px;
    background-color: $corBranca;
    position: relative;
    //font-family: Roboto, Helvetica, Arial, sans-serif;
}
body .MuiOutlinedInput-notchedOutline{
    border-color: $corCinza!important;
}

@media all and (max-width: 767px) {
  .flex-row {
    width: calc(100% / 3); //1px = border right
    
   &.first {
     width: 100%;
   }
  }

  .column {
    width: 100%;
  }
}
@media all and (max-width: 430px) {
  
  .flex-table {
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    .flex-row {
      border-bottom: 0;
    }
    .flex-row:last-of-type {
      border-bottom: solid 1px $table-border;
    }
  }
  
  .header {
    .flex-row {
      border-bottom: solid 1px;
    }
  }
  
  .flex-row {
    width: 100%; //1px = border right
    
   &.first {
     width: 100%;
     border-bottom: solid 1px $table-border;
   }
  }

  .column {
    width: 100%;
    .flex-row {
      border-bottom: solid 1px $table-border;
    }
  }

  .flex-cell {
    width: 100%; //1px = border right
  }
}

@media print {
    .noPrint {
        display:none;
    }
    .panel{
        &-main{
            margin-top: 1px;
        }
        &-content{
            padding: 0;
            .body{
                padding: 0; 
                text-align: center;
                justify-content: flex-end;
            }
        }
    }
  }
@media screen {
    .onlyPrint {
        display: none;
    }
  }

@media (min-width: 800px) and (max-width:1280px){
    .panel-main .MuiContainer-fixed {
        max-width: 800px;
    }
}